<template>
    <div class="fill-height box">
        <v-card flat class="fill-height box">
            <v-app-bar flat class="row header mx-0">
                <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
                <v-divider class="mx-3" inset vertical></v-divider>
                <span class="headline">Rental Shop</span>
            </v-app-bar>

            <v-card-text class="row content mx-0">
                <v-row align-content="center" justify="center" no-gutters>
                    <v-col cols="12" md="10" lg="7" xl="6" :class="$vuetify.breakpoint.smAndDown ? 'mb-10' : ''">
                        <v-card-title class="headline pl-0">Confirm your measurements</v-card-title>
                        <v-card-subtitle class="pl-0">The measurements below was calculated using your height and weight. <br v-if="$vuetify.breakpoint.mdAndUp">Please confirm the measurements or enter the correct ones.</v-card-subtitle>

                    
                        <v-form ref="form">
                            <v-row no-gutters class="mt-3">
                                <!-- Coat -->
                                <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                    <div class="text-center">
                                        <img :src="require('../assets/images/Coat.svg')" style="height:150px;" />
                                    </div>
                                    <div class="subtitle-1 text-center font-weight-light">
                                        <v-row>
                                            <v-col cols="3" offset="1" class="px-1">
                                                <v-text-field v-model="coatSize" label="Size" :rules="[rules.required, rules.intOnly]" 
                                                    append-icon="mdi-video" @keydown="intOnly($event)" @click:append="openVideo('295824352')"></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="px-1">
                                                <v-select v-model="coatLength" :items="coatLengthOptions" item-text="name" item-value="id"
                                                    label="Length" :rules="[rules.required]">

                                                    <template v-slot:item="{ item, on, attrs }">
                                                        <v-list-item v-on="on" v-bind="attrs">
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{ getCoatLengthSubtitle( item.id ) }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>

                                                </v-select>
                                            </v-col>
                                            <v-col cols="2" md="3" class="px-1">
                                                <v-text-field v-model="coatSleeve" label="Sleeve" :rules="[rules.required, rules.numeric]"
                                                    @keydown="numbersOnly($event)"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>

                                <!-- Pant -->
                                <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                    <div class="text-center">
                                        <img :src="require('../assets/images/Pants.svg')" style="height:150px;" />
                                    </div>
                                    <div class="subtitle-1 text-center font-weight-light">
                                        <v-row>
                                            <v-col cols="1"></v-col>
                                            <v-col cols="3" class="px-1">
                                                <v-text-field v-model="pantSize" label="Waist" :rules="[rules.required,rules.intOnly]"
                                                    append-icon="mdi-video" @keydown="numbersOnly($event)" @click:append="openVideo('296455945')"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="px-1">
                                                <v-select v-model="pantType" :items="pantTypeOptions" label="Type" item-text="name" item-value="id" 
                                                    :rules="[rules.required]"></v-select>
                                            </v-col>
                                            <v-col cols="4" class="px-1">
                                                <v-text-field v-model="pantLength" label="Outseam" :rules="[rules.required,rules.numeric]"
                                                    append-icon="mdi-video" @keydown="numbersOnly($event)" @click:append="openVideo('295826571')"></v-text-field>
                                            </v-col>
                                            <v-col cols="1"></v-col>
                                        </v-row>
                                    </div>
                                </v-col>

                                <!-- Shirt -->
                                <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                    <div class="text-center">
                                        <img :src="require('../assets/images/Shirt.svg')" style="height:150px;" />
                                    </div>
                                    <div class="subtitle-1 text-center font-weight-light">
                                        <v-row>
                                            <v-col cols="1"></v-col>
                                            <v-col cols="5">
                                                <v-text-field v-model="shirtNeckSize" label="Neck" :rules="[rules.required,rules.numeric]" 
                                                append-icon="mdi-video" @keydown="numbersOnly($event)" @click:append="openVideo('310569916')"></v-text-field>
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field v-model="shirtSleeveLength" label="Sleeve" :rules="[rules.required,rules.intOnly]" 
                                                    @keydown="numbersOnly($event)" append-icon="mdi-video" @click:append="openVideo('295827766')"></v-text-field>
                                            </v-col>
                                            <v-col cols="1"></v-col>
                                        </v-row>
                                    </div>
                                </v-col>

                                <!-- Shoes -->
                                <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                    <div class="text-center">
                                        <img :src="require('../assets/images/checkout-shoes.svg')" style="height:150px;" />
                                    </div>
                                    <div class="subtitle-1 text-center font-weight-light">
                                        <v-row>
                                            <v-col cols="3"></v-col>
                                            <v-col cols="3" class="px-1">
                                                <v-text-field v-model="shoeSize" label="Size" :rules="[rules.required,rules.numeric]" @keydown="numbersOnly($event)"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="px-1">
                                                <v-select v-model="shoeType" :items="shoeTypeOptions" label="Type" item-text="name" item-value="id" 
                                                    :rules="[rules.required]"></v-select>
                                            </v-col>
                                            <v-col cols="3"></v-col>
                                            <v-col cols="12">
                                                <span class="error--text"><b>**</b></span> Although the shoe size required, the package does not necessarily include them.
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>

                    <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep" :loading="loading">
                        <v-icon left>mdi-arrow-right</v-icon> Next
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="videoDialog" :width="$vuetify.breakpoint.name == 'xs' ? '350' : '640'" @click:outside="closeVideo">
            <div class="text-right white">
                <v-btn icon @click="closeVideo">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <iframe ref="video" title="vimeo-player" :src="`https://player.vimeo.com/video/${videoSrc}?autoplay=1`" :width="$vuetify.breakpoint.name == 'xs' ? '350' : '640'" :height="$vuetify.breakpoint.name == 'xs' ? '200' : '360'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';

export default {
    data: () => ({
        coatSize            : '',
        coatLength          : '',
        coatSleeve          : '',
        pantSize            : '',
        pantLength          : '',
        pantType            : 'R',
        shirtNeckSize       : '',
        shirtSleeveLength   : '',
        shoeSize            : '',
        shoeType            : 'W',
        lastMeasurements    : {},

        coatLengthOptions   : [
            { id: 'B', name: 'Boy' },
            { id: 'S', name: 'Short' },
            { id: 'R', name: 'Regular' },
            { id: 'L', name: 'Long' }
        ],
        pantTypeOptions     : [ 
            { id: 'B', name: 'Boy' }, 
            { id: 'R', name: 'Regular' }
        ],
        shoeTypeOptions     : [ 
            { id: 'B', name: 'Boy' }, 
            { id: 'W', name: 'Regular' }
        ],

        rules               : {
            required        : v => !!v || 'required',
            intOnly         : v => _st.isInteger( v ) || 'must be valid',
            numeric         : v => _st.isNumeric( v ) || 'must be numeric'
        },

        loading             : false,

        videoDialog         : false,
        videoSrc            : '',
    }),
    computed: {
        ...mapGetters({
            heightFeet  : 'heightFeet',
            heightInches: 'heightInches',
            weight      : 'weight',
            fit         : 'fit',
            customer    : 'customer',
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            setMeasurements: 'setMeasurements'
        }),
        async init() {
            try {
                let api = new API();

                let res = await api.post('/calculate-measurements', {
                    heightFeet      : this.heightFeet, 
                    heightInches    : this.heightInches,
                    weight          : this.weight,
                    fit             : this.fit
                });
                
                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error === true ? 'Error' : 'Warning', res.data.message, res.data.error === true ? 'error' : 'warning');
                    return;
                }

                if( res.data.data === false )
                    return;

                this.coatSize = res.data.data.jacketSize;
                this.coatLength = res.data.data.jacketLength;
                this.coatSleeve = res.data.data.shirtSleeveSize - 10;
                this.pantSize = res.data.data.pantSize;
                this.pantLength = parseInt( res.data.data.pantLength ) + 10;
                this.shirtNeckSize = res.data.data.shirtNeckSize;
                this.shirtSleeveLength = res.data.data.shirtSleeveSize;
            }
            catch(error) {
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        async nextStep() {
            if( !this.$refs.form.validate() )
                return;

            try {
                // create or update the customer measurements
                let api = new API();

                this.loading = true;
                let res = await api.post('/customer-measurements', {
                    customerId          : this.customer.id,
                    coatSize            : this.coatSize,
                    coatLength          : this.coatLength,
                    coatSleeve          : this.coatSleeve,
                    pantSize            : this.pantSize,
                    pantLength          : this.pantLength,
                    shirtNeckSize       : this.shirtNeckSize,
                    shirtSleeveLength   : this.shirtSleeveLength,
                    shoeSize            : this.shoeSize,
                });

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                    return;
                }

                this.setMeasurements( res.data.data ); // cambiar a set measurement
                this.$router.push('/checkout');
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        getCoatLengthSubtitle( lengthId ) {
            switch( lengthId ) {
                case 'B': return '';
                case 'S': return "Estimated height 5' 7'' or shorter";
                case 'R': return "Estimated height 5' 8'' - 6' 1''";
                case 'L': return "Estimaed height 6' 2'' or higher";
            }
        },
        openVideo( src ) {
            this.videoSrc = src;
            this.videoDialog = true;
        },
        closeVideo() {
            this.videoSrc = '';
            this.videoDialog = false;
        }
    },
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}
</style>